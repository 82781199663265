import React, { useCallback, useState } from "react";
import { BsArrowDown } from "react-icons/bs";
import styled from "styled-components";
import {
    MINIMUM_BILL_AMOUNT,
    SROT_RATE_5Y,
    YEAR,
} from "../../../constants/common";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getDisplayGraphData, getEntireBarValues } from "../../../service";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Bar from "./Bar";
import BottomLineDropDown from "./BottomLineDropDown";
import PieChart from "./PieChart";

import { motion } from "framer-motion";

import {
    baseRate,
    discountRate,
    OPTIONS,
    ownerShipOptions,
    propertyList,
    propertyRanges,
    subsidyRange,
} from "../../../constants/InvestmentConstant";
import { getCurrencyAmount } from "../../../helpers/helpers";
import cn from "../../../lib/cn";
import {
    opacity,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import Button from "../../../shared/ui/Button";
import EnquiryPopUp from "./EnquiryPopUp";

export const labelForDesktop = [
    "01",
    "03",
    "05",
    "07",
    "09",
    "11",
    "13",
    "15",
    "17",
    "19",
    "21",
    "23",
    "25",
];
export const labelForTablet = ["01", "05", "10", "15", "20", "25"];

export const SliderWrapper = styled.div`
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 23px;
        width: 21px;
        border-radius: 0.5rem;
        background-color: ${(props) => props.theme.color.main};
        cursor: pointer;
        @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
            width: 23px;
        }

        @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
            height: 40px;
            width: 30px;
            border-radius: 1rem;
        }
    }

    input[type="range"]::-moz-range-thumb {
        height: 30px;
        width: 30px;
        background-color: ${(props) => props.theme.color.main};
        cursor: pointer;
    }
`;

const getSubsidyRangeValue = (solarSize) => {
    let arr = Object.keys(subsidyRange);

    for (let i = 0; i < arr.length; i++) {
        let bound = arr[i].split("-");
        let lowerBound = Number(bound[0]);
        let upperBound = Number(bound[1]);
        if (solarSize >= lowerBound && solarSize < upperBound) {
            return subsidyRange[arr[i]];
        }
    }
    return 50000;
};

const getSrotRate = (plantTab, key) => {
    if (plantTab === OPTIONS.Lease) return SROT_RATE_5Y;
    else if (plantTab === OPTIONS.Purchase) return 0;
    else {
        return baseRate[key] * (1 - discountRate[key]);
    }
};

const getPieChartHeading = (
    plantTab,
    type,
    billAmount,
    solarSize,
    savings,
    pieData
) => {
    let key = type.split(" ")[0].toLowerCase();

    let solarSystemCoast = (
        solarSize *
        getSubsidyRangeValue(solarSize) *
        1.138
    ).toFixed(1);

    let levels = [];
    let monthlyPayableBill = solarSize * 120 * getSrotRate(plantTab, key);
    let govtSubsidy = getCurrencyAmount(parseInt(solarSize) * 18000);
    let paybackPeriod = `${Number(solarSystemCoast / (billAmount * 12)).toFixed(
        1
    )}Year`;
    if (
        plantTab === OPTIONS.Lease &&
        (type === propertyList[0] || type === propertyList[1])
    ) {
        levels.push(
            {
                id: 1,
                name: "Monthly Payable Bill",
                value: getCurrencyAmount(monthlyPayableBill),
            },
            {
                id: 2,
                name: " Monthly Saving With Lease",
                value: `${getCurrencyAmount(
                    billAmount - monthlyPayableBill
                )} (${Math.ceil(savings)}%) `,
            }
        );
        if (type === propertyList[0]) {
            levels.push({
                id: 3,
                name: "Government Subsidy",
                value: govtSubsidy,
            });
        } else {
            levels.push({
                id: 3,
                name: "Lifetime Saving With Solar",
                value: `${pieData.data[0]}Cr`,
            });
        }

        levels.push({
            id: 3,
            name: "Lease Plan Duration",
            value: "5Years",
        });
    } else if (plantTab === OPTIONS.Purchase && type === propertyList[0]) {
        levels.push(
            {
                id: 1,
                name: "Solar Plant Size",
                value: `${solarSize.toFixed(1)}KWp`,
            },
            {
                id: 2,
                name: "Solar Plant Cost",
                value: getCurrencyAmount(solarSystemCoast),
            },
            {
                id: 3,
                name: "Payback Period for Investment",
                value: paybackPeriod,
            },
            { id: 4, name: "Government Subsidy", value: govtSubsidy }
        );
    } else if (
        (plantTab === OPTIONS.Purchase || plantTab === OPTIONS.Loan) &&
        type === propertyList[3]
    ) {
        levels.push(
            {
                id: 1,
                name: "Solar Plant Size",
                value: `${solarSize.toFixed(1)}KWp`,
            },
            {
                id: 2,
                name: "Solar Plant Cost",
                value: getCurrencyAmount(solarSystemCoast),
            }
        );

        if (plantTab === OPTIONS.Purchase) {
            levels.push({
                id: 3,
                name: "Payback Period for Investment",
                value: paybackPeriod,
            });
        } else {
            levels.push({
                id: 3,
                name: ". Solar EMI",
                value: `2,539 Per Lakh`,
            });
        }
        levels.push({
            id: 4,
            name: "Government Subsidy",
            value: getCurrencyAmount(
                solarSize < 2 ? 30000 : solarSize < 3 ? 60000 : 78000
            ),
        });
    } else if (
        (plantTab === OPTIONS.Purchase || plantTab === OPTIONS.Loan) &&
        (type === propertyList[1] || type === propertyList[2])
    ) {
        levels.push(
            {
                id: 1,
                name: "Solar Plant Size",
                value: `${solarSize.toFixed(1)}KWp`,
            },
            {
                id: 2,
                name: "Solar Plant Cost",
                value: getCurrencyAmount(solarSystemCoast),
            }
        );
        if (plantTab === OPTIONS.Purchase) {
            levels.push({
                id: 3,
                name: "Payback Period for Investment",
                value: paybackPeriod,
            });
        } else {
            levels.push({
                id: 3,
                name: "Solar EMI",
                value: `2,539 Per Lakh`,
            });
        }
        levels.push({
            id: 4,
            name: "Accelerated Depreciation",
            value: `Upto 40% `,
        });
    }
    return levels;
};

const getGraphHeading = (
    plantTab,
    solarSize,
    billAmount,
    key,
    savings,
    pieData
) => {
    let heading = [];

    heading.push({
        id: 1,
        name: "Solar Plant Size",
        value: `${solarSize.toFixed(1)}KWp`,
    });
    let solarSystemCoast = (
        solarSize *
        getSubsidyRangeValue(solarSize) *
        1.138
    ).toFixed(1);
    let monthlyPayableBill = solarSize * 120 * getSrotRate(plantTab, key);

    if (plantTab === OPTIONS.Lease) {
        heading.push(
            {
                id: 2,
                name: "Monthly Payable Bill",
                value: getCurrencyAmount(monthlyPayableBill),
            }
            // {
            //     id: 3,
            //     name: "Savings Percentage",
            //     value: `${Math.ceil(savings)}%`,
            // }
        );
    } else if (plantTab === OPTIONS.Loan) {
        heading.push(
            {
                id: 2,
                name: "Solar Plant Cost",
                value: getCurrencyAmount(solarSystemCoast),
            }
            // {
            //     id: 3,
            //     name: "Monthly Savings Percentage",
            //     value: `${Math.ceil(savings)}%`,
            // }
        );
    } else {
        heading.push(
            {
                id: 2,
                name: "Solar Plant Cost",
                value: getCurrencyAmount(solarSystemCoast),
            }
            // {
            //     id: 3,
            //     name: "Return on Investment",
            //     value: `${Number(solarSystemCoast / (billAmount * 12)).toFixed(
            //         1
            //     )}Year`,
            // }
        );
    }

    pieData &&
        heading.push({
            id: 4,
            name: "Lifetime Savings",
            value: `${pieData}Cr`,
        });
    return heading;
};
const Calculator = ({
    defaultIndex = 0,
    heading = "Estimate Your Savings",
    text = "Finding an ideal plan can be challenging. Use our calculator to find the plan that is best for you.",
    bottomHeading = "Save on Your Current Bill Today!",
    bottomText = "With Hypersrot's plans, you can significantly cut down your monthly electricity bill and help us in fighting climate change together.",
}) => {
    const [showModal, setShowModal] = useState(false);
    const [term] = useState(5);

    const [propertyType, setPropertyType] = useState(
        propertyList[defaultIndex]
    );
    const [graph, setGraph] = useState("Bar");
    const [index, setIndex] = useState(0);
    const [amountErr, setAmountErr] = useState(false);

    const TOTAL_YEARS = 25;
    const SOLAR_TERM = term;

    const [range, setRange] = useState(
        propertyRanges[propertyType?.split(" ")[0].toLowerCase()]
    );
    let activeOwnersShips =
        ownerShipOptions[propertyType?.split(" ")[0].toLowerCase()];

    const [showOwnerShipOptions, setShowOwnerShipOptions] =
        useState(activeOwnersShips);
    const [plantTab, setPlanTab] = useState(
        activeOwnersShips?.includes("Lease")
            ? "Lease"
            : activeOwnersShips?.length > 0
            ? activeOwnersShips[0]
            : ""
    );
    const [billAmount, setBillAmount] = useState(
        propertyRanges[propertyType?.split(" ")[0].toLowerCase()].min
    );
    const [inputAmount, setInputAmount] = useState(
        propertyRanges[propertyType?.split(" ")[0].toLowerCase()].min
    );

    const handleGraphChange = (index) => {
        if (index === 0) {
            setGraph("Bar");
            setIndex(0);
        }
        if (index === 1) {
            setGraph("Pie");
            setIndex(1);
        }
    };

    const { width } = useWindowDimensions();
    const isDesktop = width > 860;
    const BAR_CHART_LABELS = isDesktop ? labelForDesktop : labelForTablet;

    const SROT_RATE = getSrotRate(
        plantTab,
        propertyType?.split(" ")[0].toLowerCase()
    );

    const handleChangePropertyType = (val) => {
        let key = val.split(" ")[0].toLowerCase();
        let newRange = propertyRanges[key];
        setRange(newRange);
        setBillAmount(newRange.min);
        setInputAmount(newRange.min);
        setShowOwnerShipOptions(ownerShipOptions[key]);
        setPropertyType(val);
        let activeOwnersShips = ownerShipOptions[key];
        setPlanTab(
            activeOwnersShips?.includes("Lease")
                ? "Lease"
                : activeOwnersShips[0]
        );
        setAmountErr(false);
    };

    const handleElectricityInput = (e) => {
        let value = e.target.value;

        if (!isNaN(value) && range.max >= value && range.min <= value) {
            setBillAmount(value);
            setInputAmount(value);
        }

        if (!isNaN(value) && (range.min > value || range.max < value)) {
            setAmountErr(true);
            setInputAmount(value);
        } else {
            setAmountErr(false);
        }
    };

    const billYearly = billAmount * YEAR;
    const savings =
        ((billYearly -
            (billYearly / baseRate[propertyType?.split(" ")[0].toLowerCase()]) *
                SROT_RATE) /
            billYearly) *
        100;

    let billUnits =
        billAmount / baseRate[propertyType?.split(" ")[0].toLowerCase()];

    let solarRequired = billUnits / 120;

    const getGraphDataSets = useCallback(() => {
        const { GREY_BAR, BLUE_BAR, GREEN_BAR } = getDisplayGraphData(
            BAR_CHART_LABELS,
            TOTAL_YEARS,
            SOLAR_TERM,
            billAmount,
            billUnits,
            SROT_RATE
        );
        let data = [
            {
                label: "Yearly Electricity Bill*",
                data: GREY_BAR,
                backgroundColor: "#2D67E0",
            },
            {
                label: "Yearly Savings With Solar*",
                data: GREEN_BAR,
                backgroundColor: "#85BFFF",
            },
        ];
        if (plantTab === OPTIONS.Purchase) {
            data.push({
                label: "",
                data: BLUE_BAR,
                backgroundColor: "transparent",
            });
        } else {
            if (plantTab === OPTIONS.Lease) {
                data.push({
                    label: "Lease Payments ",
                    data: BLUE_BAR,
                    backgroundColor: "#A0E9FF",
                });
            } else {
                data.push({
                    label: "Loan EMI Paid",
                    data: BLUE_BAR,
                    backgroundColor: "#A0E9FF",
                });
            }
        }

        return data;
    }, [
        BAR_CHART_LABELS,
        SOLAR_TERM,
        SROT_RATE,
        billAmount,
        billUnits,
        plantTab,
    ]);

    const getPieChartDisplayData = useCallback(() => {
        const { GREEN_BAR_SUM, GREY_BAR_SUM, BLUE_BAR_SUM } =
            getEntireBarValues(
                TOTAL_YEARS,
                SOLAR_TERM,
                billAmount,
                billUnits,
                SROT_RATE
            );

        let data = [];
        let labels = [];
        let backgroundColor = [];

        if (plantTab === OPTIONS.Purchase) {
            data.push(
                Number((GREY_BAR_SUM / 100).toFixed(1)),
                Number((GREEN_BAR_SUM / 100).toFixed(1))
            );

            labels.push(
                `Bill Paid Without Solar**`,
                `Savings With Solar**     `
            );
            backgroundColor.push("#2D67E0", "#A0E9FF");
        } else {
            data.push(
                Number((GREY_BAR_SUM / 100).toFixed(1)),
                Number((BLUE_BAR_SUM / 100).toFixed(1)),
                Number((GREEN_BAR_SUM / 100).toFixed(1))
            );
            labels.push(`Bill Paid Without Solar**`);

            if (plantTab === OPTIONS.Lease) {
                labels.push(`Lease Payments       `);
            } else {
                labels.push(`Loan EMI Paid     `);
            }
            labels.push(`Savings With Solar**     `);
            backgroundColor.push("#2D67E0", "#85BFFF", "#A0E9FF");
        }

        return {
            data: data,
            labels: labels,
            backgroundColor: backgroundColor,
            bilPaid: GREEN_BAR_SUM,
        };
    }, [SOLAR_TERM, SROT_RATE, billAmount, billUnits, plantTab]);
    let pieData = getPieChartDisplayData();

    return (
        <Section>
            <Container>
                <div className="flex flex-wrap justify-between items-center  lg:gap-2">
                    <motion.div
                        initial="initial"
                        whileInView="animate"
                        variants={translateContainer}
                    >
                        <SplitText
                            className="w-full lg:flex-1"
                            variants={wavyHeadings}
                            heading="h2"
                        >
                            {heading}
                        </SplitText>
                        <motion.div variants={opacity}>
                            <P className="text-secondary mt-0.4 mb-2.4 lg:mb-0">
                                {text}
                            </P>
                        </motion.div>
                    </motion.div>
                    <div className="hidden lg:flex w-[60%]  lg:w-auto">
                        <GraphTabButton
                            onChange={handleGraphChange}
                            index={index}
                        />
                    </div>
                </div>

                <div className="mt-3.2 sm:mt-4 xl:mt-4.8 2xl:mt-5.6 grid lg:grid-cols-2  lg:gap-4 xl:gap-7 place-items-center ">
                    <div className="p-6 bg-gray-100 rounded-lg w-full">
                        <div className="mb-3">
                            <P className="block mb-1 mt-2 font-thin font-urbanist xl:text-lg md:text-lsm">
                                Select a property type
                            </P>
                            <BottomLineDropDown
                                selected={propertyType}
                                data={propertyList}
                                onSelect={handleChangePropertyType}
                                icon={
                                    <P>
                                        <BsArrowDown />
                                    </P>
                                }
                            />
                        </div>

                        <div className="mb-3">
                            <div className="flex gap-1 flex-wrap w-full">
                                <div className="flex items-center justify-between w-full  gap-0.5 lg:gap-1">
                                    <P className="inline-block mb-2 mt-2 font-thin font-urbanist xl:text-lg md:text-lsm flex-wrap w-full sm:flex-1 box-border ">
                                        How much do you spend on electricity
                                        every month?
                                    </P>

                                    <div className="relative ">
                                        <span
                                            className={cn(
                                                ` absolute -bottom-0.4 -z-5 start-1
                                            w-0 h-0 
                                            border-l-[5px] border-l-transparent
                                            border-b-[6px] border-b-red/10
                                            border-r-[5px] border-r-transparent
                                            transition-all duration-300
                                            `,
                                                {
                                                    "opacity-0": !amountErr,
                                                    "opacity-100": amountErr,
                                                }
                                            )}
                                        ></span>

                                        <span
                                            className={cn(
                                                `text-sm text-red bg-red/10 block absolute start-0 end-0 px-0.4 rounded-s -bottom-2.4  flex-col z-20 transition-all duration-300`,
                                                {
                                                    "opacity-0": !amountErr,
                                                    "opacity-100": amountErr,
                                                }
                                            )}
                                        >
                                            Range is{" "}
                                            {getCurrencyAmount(range.min)} to{" "}
                                            {getCurrencyAmount(range.max)}
                                        </span>
                                        <input
                                            className={cn(
                                                "py-0.5 px-0.5 text-base border-2 rounded-lg overflow-hidden w-full sm:w-[200px] outline-none",
                                                {
                                                    "border-red hover:border-red focus:border-red":
                                                        amountErr,
                                                    "border-blue-100  hover:border-blue-100 focus:border-blue-100":
                                                        !amountErr,
                                                }
                                            )}
                                            onChange={handleElectricityInput}
                                            value={inputAmount}
                                        />
                                    </div>
                                </div>
                            </div>

                            <SliderWrapper>
                                <div className="relative mt-1 sm:mt-1.2 mb-9.6">
                                    <label
                                        htmlFor="labels-range-input"
                                        className="sr-only"
                                    >
                                        Labels range
                                    </label>
                                    <input
                                        id="labels-range-input"
                                        type="range"
                                        value={billAmount}
                                        min={range.min}
                                        max={range.max}
                                        // step={countStep()}
                                        onChange={(e) => {
                                            setBillAmount(e.target.value);
                                            setInputAmount(e.target.value);
                                        }}
                                        className="w-full h-0.2 bg-blue-500 rounded-lg appearance-none cursor-pointer"
                                    />
                                    <span
                                        className="text-sm text-primary  absolute start-0 
                                   -bottom-3.2 lg:-bottom-4.8"
                                    >
                                        Min ({getCurrencyAmount(range.min)})
                                    </span>

                                    <span className="text-sm text-primary  absolute end-0 -bottom-3.2 lg:-bottom-4.8">
                                        Max ({getCurrencyAmount(range.max)})
                                    </span>
                                </div>
                            </SliderWrapper>
                        </div>

                        <div className="mb-3">
                            <P className="block mb-1 mt-2 font-thin font-urbanist xl:text-lg md:text-lsm">
                                Select an Ownership Plan
                            </P>

                            <div className="flex w-full">
                                <div className="flex items-center justify-center w-full ">
                                    <div
                                        className="flex w-full bg-gray-100   border-l-0 border-r-0
                                    "
                                    >
                                        {showOwnerShipOptions &&
                                            showOwnerShipOptions?.map(
                                                (option, index) => (
                                                    <div
                                                        role="button"
                                                        key={index}
                                                        className={cn(
                                                            `flex-1 transition-colors duration-300 text-sm sm:text-base xl:text-lg 2xl:text-xl md:text-lsm font-normal flex items-center justify-center border cursor-pointer px-1.2 py-0.8  xl:py-1.2
                                                         
                                                        `,
                                                            {
                                                                "bg-primary text-white border-primary ":
                                                                    plantTab ===
                                                                    option,
                                                                "bg-gray-100 text-secondary border-blue-500 ":
                                                                    plantTab !==
                                                                    option,
                                                                "rounded-l-sm":
                                                                    index === 0,
                                                                "rounded-r-sm":
                                                                    index ===
                                                                    showOwnerShipOptions.length -
                                                                        1,
                                                                relative:
                                                                    option ===
                                                                    "Lease",
                                                            }
                                                        )}
                                                        onClick={() =>
                                                            setPlanTab(option)
                                                        }
                                                    >
                                                        {option}
                                                        {option === "Lease" && (
                                                            <span
                                                                className="absolute py-0.4 h-[18px] flex items-center justify-center px-0.6 bg-orange text-[10px] leading-[1.5rem] text-primary rounded-full "
                                                                style={{
                                                                    top: "0",
                                                                    right: "0",
                                                                    borderBottomLeftRadius:
                                                                        "5px",
                                                                }}
                                                            >
                                                                Best
                                                            </span>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex lg:hidden w-full sm:w-[60%] md:w-[50%] mt-2 ">
                        <GraphTabButton
                            onChange={handleGraphChange}
                            index={index}
                        />
                    </div>
                    <div
                        className={
                            " pt-5.6 sm:pt-6.4 lg:p-0 lg:pt-0  rounded-lg w-full lg:block"
                        }
                    >
                        {graph === "Bar" ? (
                            <div>
                                <div className="flex flex-wrap gap-0.5 justify-between">
                                    {getGraphHeading(
                                        plantTab,
                                        Number(solarRequired),
                                        billAmount,
                                        propertyType
                                            .split(" ")[0]
                                            .toLowerCase(),
                                        savings,
                                        pieData.data[2]
                                    ).map((item) => (
                                        <div
                                            key={item.id}
                                            className="flex  flex-col justify-center items-center"
                                        >
                                            <P className="text-secondary">
                                                {item.name}
                                            </P>
                                            <H4 className="text-lg sm:text-xl xl:text-2xl 2xl:text-2xl font-semibold mt-0.5">
                                                {item.value}
                                            </H4>
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-3 pt-2 w-full">
                                    <Bar
                                        labels={BAR_CHART_LABELS}
                                        datasets={getGraphDataSets()}
                                    />
                                    <P className="text-xs sm:text-sm lg:text-sm xl:text-sm  2xl:text-sm">
                                        * All values shown above is in{" "}
                                        {MINIMUM_BILL_AMOUNT > billAmount
                                            ? "Thousands"
                                            : "Lakhs"}
                                    </P>
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-start flex-wrap justify-between">
                                <div className="w-full lg:w-[48%]">
                                    <PieChart data={pieData} />
                                    <P className="text-xs sm:text-sm lg:text-sm xl:text-sm  2xl:text-sm">
                                        ** All values shown above is in Crores
                                    </P>
                                </div>

                                <div className=" mt-2  lg:mt-0 pt-0 w-full lg:w-[48%]">
                                    <div className="p-6 rounded-lg w-full">
                                        {getPieChartHeading(
                                            plantTab,
                                            propertyType,
                                            billAmount,
                                            Number(solarRequired),
                                            savings,
                                            pieData
                                        ).map((item, i) => (
                                            <BarWithValues
                                                key={item.id}
                                                label={item.name}
                                                value={item.value}
                                                bottomLabel={i !== 3}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-wrap justify-between items-center bg-linear-tag p-2.4  border border-blue-400 gap-3 rounded-sm mt-3.2  xl:mt-4.8 2xl:mt-5.6">
                    <div className="mb-1.2 xl:mb-0 w-full xl:w-7/12">
                        <H4 className="font-semibold text-lg sm:text-xl xl:text-2xl 2xl:text-2xl mb-1">
                            {bottomHeading}
                        </H4>
                        <P className="text-secondary">{bottomText}</P>
                    </div>
                    <Button onClick={() => setShowModal(true)}>
                        Got an enquiry?
                    </Button>
                    <EnquiryPopUp
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                        callback={() => setShowModal(false)}
                    />
                </div>
            </Container>
        </Section>
    );
};

export default Calculator;

const GraphTabButton = ({ onChange, index }) => {
    return (
        <div className="flex w-full bg-gray-100 border-0.5 border-blue-500  rounded-full">
            {["Your Savings", "Plan Details"].map((option, ind) => (
                <button
                    key={option}
                    className={`flex-1 py-1 px-1 rounded-full transition-colors duration-300 xl:text-lg md:text-lsm font-normal flex items-center justify-center text-nowrap ${
                        index === ind
                            ? "bg-primary text-white"
                            : "bg-gray-100 text-secondary"
                    } ${option === "Plan Details" ? "relative" : ""}`}
                    onClick={() => onChange(ind)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

const BarWithValues = ({ label, value, bottomLabel = true }) => {
    return (
        <div className="mb-1 xl:mb-1.5">
            <div className="flex justify-between flex-wrap gap-0.5 lg:gap-0 lg:block">
                <P className="text-secondary">{label}</P>
                <H4 className="text-end font-semibold text-lg sm:text-xl xl:text-2xl 2xl:text-2xl mt-0 mb-1 xl:my-1.5">
                    {value}
                </H4>
            </div>

            {bottomLabel && (
                <div className="">
                    <hr className="w-full border-0.5 border-blue-500" />
                </div>
            )}
        </div>
    );
};
