import React from "react";
import Container from "../../shared/Container";
import ProposalTable from "./ProposalTable";
import SideBar from "./SideBar";

const ProposalContent = ({
    currentTab,
    currentProposal,
    proposalData,
    selected,
    tableData,
    options,
    handleSelectedProposal,
    connectionType,
    checkedOptions,
    admins,
    handleAdmins,
}) => {
    return (
        <Container className="px-1 mb-4">
            <div className="bg-white rounded-lg md:items-start p-1 border md:px-1.5 md:py-1.5  flex flex-col md:flex-row gap-1.6 border-gray-200">
                <SideBar
                    currentProposal={currentProposal}
                    proposalData={proposalData}
                    handleSelectedProposal={handleSelectedProposal}
                />
                {/* @DESC:: We need to follow this  @habib610 Sat February 01,2025 */}
                <ProposalTable
                    connectionType={connectionType}
                    currentTab={currentTab}
                    checkedOptions={checkedOptions}
                    selected={selected}
                    tableData={tableData}
                    options={options}
                    handleAdmins={handleAdmins}
                    admins={admins}
                />
            </div>
        </Container>
    );
};

export default ProposalContent;
