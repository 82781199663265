import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { SlPencil } from "react-icons/sl";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { connectionTypeToCategory } from "../../constants/InvestmentConstant";
import { ERROR_MSG } from "../../constants/common";
import { RESET_LOADER, SET_LOADER } from "../../constants/contexConstant";
import {
    CURRENT_MASTER_DATA_ENDPOINT,
    PROPOSAL,
    SAVE_PROPOSAL_DATA_ENDPOINT,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { getKeyByValue } from "../../helpers/helpers";
import request from "../../services/request";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import CustomButton from "../../shared/ui/CustomButton";
import TextField from "../../shared/ui/Form/TextField";
import TableComponent from "../../shared/ui/Table";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";

const columns = ["Description", "Amount"];
const getAdditionalData = (additionalData) => {
    let firstAdditionalData = additionalData[0];
    if (additionalData?.length > 1) {
        let additionalItems = [];
        additionalData.forEach((el) => {
            additionalItems.push({
                itemDescription: el.itemDescription,
                itemPrice: parseInt(el.itemPrice),
                itemGst: el.itemGst,
            });
        });
        return additionalItems;
    } else if (
        firstAdditionalData.itemDescription === "" ||
        firstAdditionalData.itemPrice === ""
    ) {
        return [];
    } else {
        return [
            {
                itemDescription: firstAdditionalData.itemDescription,
                itemPrice: parseInt(firstAdditionalData.itemPrice),
                itemGst: firstAdditionalData.itemGst,
            },
        ];
    }
};

const ProposalTable = ({
    connectionType,
    totalUnits,
    consumersBill,
    isProposalGenerated,
    selectedOptions,
    checkedState,
    additionalData,
}) => {
    const { dispatch } = useContextState();
    const history = useHistory();

    const options = selectedOptions;

    const { propertyId } = useParams();
    const [solarData, setSolarData] = useState(null);
    const [solarPlantSize, setSolarPlantSize] = useState(0);
    const [subsidyAmount, setSubsidyAmount] = useState(0);
    const [costKw, setCostKw] = useState(0);

    const [averageMonthlyBill, setAverageMonthlyBill] = useState(
        Number(consumersBill)
    );

    const [planDuration, setPlanDuration] = useState("5 Years");
    const [GST, setGST] = useState(0);
    const [upfront, setUpfront] = useState(0);
    const [leaseTable, setLeaseTable] = useState({});
    const [currentTab, setCurrentTab] = useState(0);

    const handleTabClick = (index) => {
        setCurrentTab(index);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch({ type: SET_LOADER });
                const endpoint = CURRENT_MASTER_DATA_ENDPOINT;
                const data = await request.authGet({ endpoint });
                dispatch({ type: RESET_LOADER });
                setSolarData(JSON.parse(data.data.masterData.data));
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                dispatch({ type: RESET_LOADER });
            }
        };
        fetchData();
    }, []);

    const calculateSubsidy = useCallback(
        (size) => {
            if (connectionType === "Residential Society") {
                let subAmt =
                    solarData?.government_subsidy["residential-society"][
                        "0-500"
                    ] * size;
                setSubsidyAmount(subAmt.toLocaleString());
            } else if (connectionType === "Individual Homes") {
                let subAmt;
                const subsidyData =
                    solarData?.government_subsidy["individual-homes"];
                if (subsidyData) {
                    Object.keys(subsidyData).forEach((range) => {
                        const [min, max] = range.split("-").map(Number);
                        if (size >= min && size < max) {
                            subAmt = subsidyData[range];
                        }
                    });
                }
                if (subAmt) {
                    setSubsidyAmount(subAmt.toLocaleString());
                }
            }
        },
        [connectionType, solarData?.government_subsidy]
    );

    const calculateTotalCost = useCallback(
        (size) => {
            let costPerKW;
            const pricingData =
                connectionType === "Residential Society" ||
                connectionType === "Individual Homes"
                    ? solarData?.pricing?.loan_and_purchase?.subsidy
                    : solarData?.pricing?.loan_and_purchase["non-subsidy"];

            if (pricingData) {
                Object.keys(pricingData).forEach((range) => {
                    const [min, max] = range.split("-").map(Number);
                    if (size > min && size <= max) {
                        costPerKW = pricingData[range];
                    }
                });
            }

            if (costPerKW) {
                setCostKw(parseInt(costPerKW));
                const savemt = size * costPerKW;
                const totalCost = savemt * 1.138;
                setGST(parseInt(savemt * 0.138));
                setUpfront(totalCost * 0.3);
            } else {
                console.error(
                    "Could not find appropriate range for the provided size"
                );
            }
        },
        [connectionType, solarData?.pricing.loan_and_purchase]
    );

    const calculateLeasePricingTable = useCallback(
        (solarPlantSize, averageMonthlyBill) => {
            const investorRate = Number(
                solarData?.charges["residential-society"].investor_rate["5Y"]
            );
            const hypersrotRate = Number(
                solarData?.charges["residential-society"].hypersrot_rate.per_KW
            );

            const fixedMonthlyRental =
                (investorRate + hypersrotRate) * 120 * solarPlantSize;

            const bankGuarantee = fixedMonthlyRental * 4;
            const savingsOnExistingBillsPercentage =
                ((averageMonthlyBill - fixedMonthlyRental) /
                    averageMonthlyBill) *
                100;

            const governmentSubsidy =
                solarData?.government_subsidy["residential-society"]["0-500"] *
                solarPlantSize;

            const planDuration = 5 * 12; // Assuming a plan duration of 5 years
            const effectiveSavingsPerMonth =
                ((averageMonthlyBill -
                    fixedMonthlyRental +
                    governmentSubsidy / planDuration) /
                    averageMonthlyBill) *
                100;

            return {
                fixedMonthlyRental: fixedMonthlyRental.toLocaleString(),
                bankGuarantee: bankGuarantee.toLocaleString(),
                savingsOnExistingBillsPercentage:
                    savingsOnExistingBillsPercentage.toFixed(2),
                governmentSubsidy: governmentSubsidy.toLocaleString(),
                effectiveSavingsPerMonth: effectiveSavingsPerMonth.toFixed(2),
            };
        },
        [solarData?.charges, solarData?.government_subsidy]
    );

    useEffect(() => {
        if (totalUnits > 0) {
            const size = parseInt(totalUnits / 120);
            setAverageMonthlyBill(Number(consumersBill));
            setSolarPlantSize(size.toFixed(2));
            calculateSubsidy(size.toFixed(2));
            calculateTotalCost(size.toFixed(2));
            setLeaseTable(
                calculateLeasePricingTable(size, Number(consumersBill))
            );
        }
    }, [
        calculateLeasePricingTable,
        calculateSubsidy,
        calculateTotalCost,
        consumersBill,
        totalUnits,
    ]);

    useEffect(() => {
        setCurrentTab(0);
    }, [selectedOptions]);

    const planDurationYears = useMemo(() => {
        const yearsString = planDuration.split(" ")[0];
        return yearsString + "Y";
    }, [planDuration]);

    const billPayableToHypersrot = useMemo(() => {
        return (
            solarPlantSize *
            (solarData?.charges.commercial.investor_rate[planDurationYears] +
                solarData?.charges.commercial.hypersrot_rate.per_KW) *
            120
        );
    }, [
        planDurationYears,
        solarData?.charges.commercial.hypersrot_rate.per_KW,
        solarData?.charges.commercial.investor_rate,
        solarPlantSize,
    ]);

    const billWithoutGST = useMemo(() => {
        return Number(consumersBill) / 1.18;
    }, [averageMonthlyBill]);

    const Gsthyper = useMemo(() => {
        return billPayableToHypersrot * 0.18;
    }, [billPayableToHypersrot, solarData]);

    const totalBillPayable = useMemo(() => {
        return billPayableToHypersrot + Gsthyper;
    }, [billPayableToHypersrot, GST]);

    const monthlySavings = useMemo(() => {
        return Number(consumersBill) - billPayableToHypersrot;
    }, [averageMonthlyBill, billPayableToHypersrot]);

    const savingsPercentage = useMemo(() => {
        return (monthlySavings.toFixed(0) / averageMonthlyBill) * 100;
    }, [monthlySavings, averageMonthlyBill]);

    let isCommercial =
        connectionType === connectionTypeToCategory.COMMERCIAL ||
        connectionType === connectionTypeToCategory.INDUSTRIAL;

    let isLease = options[currentTab] === "Lease";

    let buildTableData = () => {
        let additional = getAdditionalData(additionalData);
        let totalWithAdditional =
            (additional?.length && additional?.length !== 0
                ? additional.reduce(
                      (prev, el) => prev + parseInt(el.itemPrice),
                      0
                  )
                : 0) +
            parseInt(costKw * solarPlantSize) +
            GST;

        let initialTableData = isLease
            ? [
                  {
                      Description: "Fixed Monthly Rental",
                      Amount: (
                          <P className="font-semibold">
                              {isProposalGenerated
                                  ? leaseTable?.fixedMonthlyRental
                                  : "XXXX"}
                          </P>
                      ),
                  },
                  {
                      Description: "Bank Guarantee",
                      Amount: (
                          <P className="font-semibold">
                              {isProposalGenerated
                                  ? leaseTable?.bankGuarantee
                                  : "XXXXX"}
                          </P>
                      ),
                  },
                  {
                      Description: "Savings on Existing Bills",
                      Amount: (
                          <P className="font-semibold">
                              {isProposalGenerated
                                  ? `${leaseTable?.savingsOnExistingBillsPercentage} %`
                                  : "XXXX"}
                          </P>
                      ),
                  },
                  {
                      Description: "Government Subsidy",
                      Amount: (
                          <P className="font-semibold">
                              {isProposalGenerated
                                  ? leaseTable?.governmentSubsidy
                                  : "XXXXX"}
                          </P>
                      ),
                  },
                  {
                      Description: "Effective Savings Per Month",
                      Amount: (
                          <P className="font-semibold">
                              {isProposalGenerated
                                  ? ` ${leaseTable?.effectiveSavingsPerMonth} %`
                                  : "XXXX"}
                          </P>
                      ),
                  },
              ]
            : [
                  {
                      Description: (
                          <P className="text-secondary xl:text-lsm">
                              Solar Plant Cost:
                              <span className="font-medium">
                                  {`${solarPlantSize.toLocaleString()} KWp`}
                              </span>{" "}
                              <span className="text-sm">
                                  @{parseInt(costKw).toLocaleString()}
                                  /KWp
                              </span>
                          </P>
                      ),
                      Amount: (
                          <P className="font-semibold">
                              {parseInt(
                                  costKw * solarPlantSize
                              ).toLocaleString()}
                          </P>
                      ),
                  },

                  {
                      Description: (
                          <P className="text-secondary 2xl:text-base">
                              GST @ 13.8%
                          </P>
                      ),
                      Amount: (
                          <P className="font-semibold">
                              {GST.toLocaleString()}
                          </P>
                      ),
                  },
                  ...(additional?.length && additional.length !== 0
                      ? additional.map((item) => ({
                            Description: (
                                <P className="text-secondary 2xl:text-base ">
                                    {item.itemDescription}
                                    {item.itemGst && item.itemGst !== 0 && (
                                        <span className="text-primary text-xs block">
                                            GST @{" "}
                                            <span className=" font-medium">
                                                {item.itemGst}%
                                            </span>
                                        </span>
                                    )}
                                </P>
                            ),
                            Amount: (
                                <P className="font-semibold">
                                    {parseInt(item.itemPrice).toLocaleString()}
                                </P>
                            ),
                        }))
                      : []),
                  {
                      Description: (
                          <P className="text-secondary font-semibold xl:text-lsm">
                              Total Amount
                          </P>
                      ),
                      Amount: (
                          <P className="font-semibold">
                              {totalWithAdditional.toLocaleString()}
                          </P>
                      ),
                  },
              ];

        return initialTableData;
    };

    const tableData = buildTableData();

    const saveProposal = async () => {
        const body = {
            plantId: propertyId,
            category: getKeyByValue(connectionType),
            consumerBill: `${parseInt(consumersBill)}`,
            consumerTotalUnits: `${parseInt(totalUnits)}`,
            plantDuration: planDuration.split(" ")[0],
            purchase: checkedState["purchase"] ? "INTERESTED" : "REJECTED",
            lease: checkedState["lease"] ? "INTERESTED" : "REJECTED",
            loan: checkedState["loan"] ? "INTERESTED" : "REJECTED",
        };

        if (checkedState["purchase"] || checkedState["loan"]) {
            body.additionalItems = getAdditionalData(additionalData);
        }

        try {
            dispatch({ type: SET_LOADER });
            const response = await request.authPost({
                endpoint: SAVE_PROPOSAL_DATA_ENDPOINT,
                body: body,
            });
            dispatch({ type: RESET_LOADER });

            if (response.status === "SUCCESS") {
                history.push(`${PROPOSAL}/${propertyId}`, {
                    connectionType,
                    consumersBill,
                    totalUnits,
                    checkedState,
                });
            } else {
                toast.error("Failed to save proposal:");
                return false;
            }
        } catch (error) {
            dispatch({ type: RESET_LOADER });
            toast.error(typeof error === "string" ? error : ERROR_MSG);

            return false;
        }
    };
    return (
        <div className="bg-gray-170 w-full md:w-[65%] lg:w-[75%] xl:w-[80%] rounded-2xl p-1 md:p-2">
            <div className="flex justify-between items-center gap-1">
                <div>
                    <SwitchingTabs
                        options={options}
                        currentTab={currentTab}
                        onTabClick={handleTabClick}
                        border={"!rounded-[3rem] lg:rounded-[3rem]"}
                        bestlabel={false}
                    />
                </div>
                <div className="flex items-center gap-1 md:gap-2">
                    <CustomButton
                        disabled={true}
                        icon={<IoEyeOutline />}
                        onClick={() => {}}
                    />
                    <CustomButton
                        disabled={true}
                        icon={<SlPencil />}
                        onClick={() => {}}
                    />
                </div>
            </div>
            <div className="bg-white border-2 border-dashed rounded-xl px-1 py-1 md:px-2 md:py-1.5 gap-3.2 mt-2 border-gray-220 flex justify-between flex-wrap items-stretch md:items-center">
                {isCommercial && options[currentTab] === "Lease" ? (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Bill (Without GST)"
                            value={
                                isProposalGenerated
                                    ? billWithoutGST.toLocaleString()
                                    : "XXX"
                            }
                            disabled={true}
                        />
                    </div>
                ) : (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Solar Plant Size"
                            value={
                                isProposalGenerated
                                    ? `${solarPlantSize} KWp`
                                    : "XXX"
                            }
                            disabled={true}
                        />
                    </div>
                )}

                {options[currentTab] === "Lease" &&
                    connectionType === "Commercial" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="GST (18%)"
                                value={
                                    isProposalGenerated
                                        ? Gsthyper.toLocaleString()
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )}

                {options[currentTab] === "Lease" &&
                connectionType === "Commercial" ? (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Total Bill Payable"
                            value={
                                isProposalGenerated
                                    ? totalBillPayable.toLocaleString()
                                    : "XXXX"
                            }
                            disabled={true}
                        />
                    </div>
                ) : (
                    options[currentTab] === "Lease" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="Units Consumed"
                                value={
                                    isProposalGenerated
                                        ? Number(totalUnits).toLocaleString()
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )
                )}

                {options[currentTab] === "Lease" &&
                connectionType === "Commercial" ? (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Monthly Savings"
                            value={
                                isProposalGenerated
                                    ? monthlySavings.toLocaleString()
                                    : "XXXX"
                            }
                            disabled={true}
                        />
                    </div>
                ) : (
                    options[currentTab] === "Lease" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="Average Monthly Bill"
                                value={
                                    isProposalGenerated
                                        ? Number(consumersBill).toLocaleString()
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )
                )}

                {(connectionType === "Residential Society" ||
                    connectionType === "Individual Homes") && (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Subsidy Amount"
                            value={
                                isProposalGenerated ? subsidyAmount : "XXXXX"
                            }
                            disabled={true}
                        />
                    </div>
                )}

                {(connectionType === "Commercial" ||
                    connectionType === "Industrial") &&
                    options[currentTab] !== "Lease" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="GST"
                                value={
                                    isProposalGenerated
                                        ? GST.toLocaleString()
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )}

                {connectionType === "Commercial" &&
                    options[currentTab] === "Lease" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="Savings Percentage"
                                value={
                                    isProposalGenerated
                                        ? `${savingsPercentage.toFixed(2)}%`
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )}

                {options[currentTab] === "Loan" && (
                    <div className="w-full md:w-1/3 xl:w-1/3">
                        <TextField
                            label="Upfront Payment (30%)"
                            value={isProposalGenerated ? upfront : "XXXX"}
                            disabled={true}
                        />
                    </div>
                )}
            </div>
            <div className="bg-white border-2 border-dashed rounded-xl px-1 py-1 md:px-2 md:py-1.5 my-2 border-gray-220">
                <TableComponent
                    columns={columns}
                    data={tableData}
                    headerColor="bg-blue-300"
                    oddRowColor="bg-gray-170"
                    additionalRowClassName="rounded-xl"
                    borderStyle="border-collapse border border-gray-200"
                    fontSize="text-xs md:text-sm"
                />
            </div>
            <Button
                onClick={() => saveProposal()}
                disabled={!isProposalGenerated}
            >
                Save Proposal
            </Button>
        </div>
    );
};

export default ProposalTable;
