import React, { useCallback, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { logoDark } from "../../assets";
import { ROLE } from "../../constants/common";
import {
    ADMIN,
    ADMIN_LABEL,
    AUDIT,
    AUDIT_LABEL,
    CONTACTUS,
    CONTACTUS_LABEL,
    DASHBOARD,
    DASHBOARD_LABEL,
    LANDING,
    LOGIN,
    LOGIN_LABEL,
    ONBOARDING,
    ONBOARDING_LABEL,
    PAYMENT,
    PAYMENT_LABEL,
    PRICING_LABELS,
    PURCHASE_DETAILS,
    SURVEY_VIEW,
    SURVEY_VIEW_LABEL,
    UPDATE_MASTER_DATA,
    UPDATE_MASTER_DATA_LABEL,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { checkIsAllowed } from "../../helpers/helpers";
import useAuth from "../../hooks/useAuth";
import useHeaderScroll from "../../hooks/useHeaderScroll";
import useScroll from "../../hooks/useScroll";
import cn from "../../lib/cn";
import { logoWhite } from "../../resources/js/images";
import auth from "../../services/auth";
import Container from "../Container";
import Button from "../ui/Button";
import Hamburger from "./Hamburger";
import HeaderMenu from "./HeaderMenu";
import HeaderProfileDropDown from "./HeaderProfileDropDown";

const disableHeaderScrollLinks = [
    DASHBOARD,
    PAYMENT,
    AUDIT,
    SURVEY_VIEW,
    ADMIN,
    UPDATE_MASTER_DATA,
    "/editProposal",
    "/viewProposal",
    "/generate",
];

export const AUTH_DASHBOARD_DOWN_LINKS = [
    {
        name: DASHBOARD_LABEL,
        path: DASHBOARD,
    },
    {
        name: AUDIT_LABEL,
        path: AUDIT,
    },
    {
        name: PAYMENT_LABEL,
        path: PAYMENT,
    },
    {
        name: SURVEY_VIEW_LABEL,
        path: SURVEY_VIEW,
    },
];

export const ADMIN_LINKS = [
    {
        name: ADMIN_LABEL,
        path: ADMIN,
    },
    {
        name: UPDATE_MASTER_DATA_LABEL,
        path: UPDATE_MASTER_DATA,
    },
];
export const CONSUMER_LINKS = [
    {
        name: "Residential Societies",
        path: PRICING_LABELS.RESIDENTIAL_SOCIETIES,
    },
    {
        name: "Individual Homes",
        path: PRICING_LABELS.INDIVIDUAL_HOMES,
    },
    {
        name: "Commercial",
        path: PRICING_LABELS.COMMERCIAL,
    },
    {
        name: "Industrial",
        path: PRICING_LABELS.INDUSTRIAL,
    },
];
const commonLinks = [
    {
        dropdown: "Consumer Type",
        links: CONSUMER_LINKS,
    },
    { name: CONTACTUS_LABEL, path: CONTACTUS },
    // { name: ABOUT_US_LABEL, path: ABOUT },
];

const DROP_DOWN_LINKS = [{ name: CONTACTUS_LABEL, path: CONTACTUS }];

const unAuthenticLinks = [
    { name: ONBOARDING_LABEL, path: ONBOARDING },
    { name: LOGIN_LABEL, path: LOGIN },
];

const baseStyles =
    "fixed z-50 transition-all ease-in-out duration-500 left-0 right-0";

const getHeaderLinks = (isAuth, roles) => {
    if (isAuth && roles && checkIsAllowed(roles, [ROLE.SUPER_ADMIN])) {
        return [
            {
                name: UPDATE_MASTER_DATA_LABEL,
                path: UPDATE_MASTER_DATA,
            },
            {
                name: DASHBOARD_LABEL,
                path: `${ADMIN}${DASHBOARD}`,
            },
            // ...commonLinks,
            // {
            //     dropdown: "Admin",
            //     links: ADMIN_LINKS,
            // },
            // {
            //     dropdown: "Dashboard",
            //     links: AUTH_DASHBOARD_DOWN_LINKS,
            // },
        ];
    } else if (
        isAuth &&
        roles &&
        checkIsAllowed(roles, [ROLE.OPERATION_MANAGER, ROLE.SALES_MANAGER])
    ) {
        return [
            {
                name: DASHBOARD_LABEL,
                path: `${ADMIN}${DASHBOARD}`,
            },
            // ...commonLinks,
            // {
            //     dropdown: "Admin",
            //     links: ADMIN_LINKS,
            // },
            // {
            //     dropdown: "Dashboard",
            //     links: AUTH_DASHBOARD_DOWN_LINKS,
            // },
        ];
    } else if (isAuth) return commonLinks;
    return [
        {
            dropdown: "Consumer Type",
            links: CONSUMER_LINKS,
        },
        ...unAuthenticLinks,
    ];
};

const Header = ({ handleModal }) => {
    const { state, dispatch } = useContextState();
    const { scrollY } = useScroll();
    const history = useHistory();
    const [showBurger, setShowBurger] = useState(false);
    const { visible } = useHeaderScroll();
    const { pathname } = useLocation();

    const scrollStyles = visible ? "top-0" : "top-[-100px]";

    const { isAuth } = useAuth(pathname);

    const links = getHeaderLinks(isAuth, state?.user?.roles);

    let getDisabledLinks = useCallback((pathname) => {
        for (let i = 0; i < disableHeaderScrollLinks.length; i++) {
            if (pathname.includes(disableHeaderScrollLinks[i])) return true;
        }
        return false;
    }, []);

    const isVisible = useMemo(
        () => scrollY > 50 || getDisabledLinks(pathname),
        [getDisabledLinks, pathname, scrollY]
    );

    const handleLogout = () => {
        auth.logout(dispatch)
            .then(() => {
                history.push(LANDING);
            })
            .catch(() => {});
    };

    return (
        <div>
            <header
                className={cn(baseStyles, scrollStyles, {
                    "bg-gray-250": isVisible,
                })}
                style={{
                    zIndex: "99",
                }}
            >
                <Container>
                    <nav
                        className={`flex items-center justify-between py-1 !pt-4 lg:p-2.4
                        rounded-1.5 `}
                        aria-label="Global"
                    >
                        <div className="flex lg:flex-1 py-0.5 sm:py-0">
                            <Link
                                to={LANDING}
                                className="duration-500 transition-all"
                            >
                                <span className="sr-only">Hypersrot </span>
                                <img
                                    src={logoDark}
                                    className={`${
                                        isVisible ||
                                        (showBurger &&
                                            document.body.clientWidth < 830) ||
                                        pathname.includes(`${PURCHASE_DETAILS}`)
                                            ? "h-[30px] sm:h-[37px] w-auto "
                                            : "h-[0] w-0 overflow-hidden opacity-0"
                                    } transition-all ease-in-out duration-500`}
                                    alt="HyperSrot_logo"
                                />
                                <img
                                    src={logoWhite}
                                    className={`${
                                        !isVisible &&
                                        !pathname.includes(
                                            `${PURCHASE_DETAILS}`
                                        ) &&
                                        (!showBurger ||
                                            !(document.body.clientWidth < 830))
                                            ? "h-[30px] sm:h-[37px] w-auto "
                                            : "h-[0] w-0 overflow-hidden opacity-0 "
                                    } ease-in-out duration-500 transition-all`}
                                    alt="HyperSrot_logo"
                                />
                            </Link>
                        </div>
                        <div className="flex xl:hidden">
                            {state.user && (
                                <HeaderProfileDropDown
                                    onLogout={handleLogout}
                                    links={DROP_DOWN_LINKS}
                                    user={state?.user}
                                    onBurgerOpen={() =>
                                        setShowBurger((prev) => !prev)
                                    }
                                />
                            )}
                            <button
                                type="button"
                                className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5  flex-col duration-150 ${
                                    isVisible ? "text-primary" : "text-white"
                                }`}
                                onClick={() => setShowBurger(true)}
                            >
                                <span className="sr-only">Open main menu</span>

                                <svg
                                    className="hidden sm:block xl:hidden "
                                    width="37"
                                    height="28"
                                    viewBox="0 0 37 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.73438 2H34.7344M8.73437 14H34.7344M14.7344 26H34.7344"
                                        stroke="currentColor"
                                        strokeWidth="3.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <svg
                                    width="30"
                                    height="31"
                                    viewBox="0 0 30 31"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sm:hidden"
                                >
                                    <path
                                        d="M5.00391 8.25488H25.0039M8.75391 15.7549H25.0039M12.5039 23.2549H25.0039"
                                        stroke="currentColor"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div className="hidden xl:flex lg:gap-x-12 items-center justify-end ">
                            {links.map((item, index) =>
                                item?.dropdown ? (
                                    <HeaderMenu
                                        key={index}
                                        label={item.dropdown}
                                        links={item.links}
                                        isVisible={isVisible}
                                    />
                                ) : (
                                    <Link
                                        to={item.path}
                                        key={item.name}
                                        className={`text-btn-s font-normal leading-6  hover:scale-110 duration-150 ${
                                            isVisible
                                                ? "text-primary"
                                                : "text-white"
                                        }`}
                                    >
                                        {item.name}
                                    </Link>
                                )
                            )}
                            {!state.user && (
                                <Button
                                    variant="primary"
                                    className="hidden lg:flex text-white"
                                    onClick={() => {
                                        history.push(CONTACTUS);
                                    }}
                                >
                                    {CONTACTUS_LABEL}
                                </Button>
                            )}
                            {state.user && (
                                <Button
                                    onClick={() => handleModal()}
                                    className="!rounded-[4rem] px-5"
                                >
                                    Enquiry
                                </Button>
                            )}
                            {state.user && (
                                <HeaderProfileDropDown
                                    onLogout={handleLogout}
                                    links={DROP_DOWN_LINKS}
                                    user={state?.user}
                                    onBurgerOpen={() =>
                                        setShowBurger((prev) => !prev)
                                    }
                                />
                            )}
                        </div>
                    </nav>
                </Container>
            </header>
            <Hamburger
                links={links}
                showBurger={showBurger}
                onClick={(val) => setShowBurger(val)}
                handleLogout={handleLogout}
                user={state?.user}
                handleModal={handleModal}
            />
        </div>
    );
};

export default Header;
