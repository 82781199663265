import React from "react";
import { logoDark } from "../../../resources/js/images";
import P from "../../../shared/typography/P";

const Heading = ({ date, propertyName, street }) => {
    return (
        <div className="bg-white flex flex-col gap-2.4 p-1 sm:p-2 border-2 border-gray-220 rounded-xl border-dashed">
            <div className="flex w-full justify-between items-center">
                <img
                    src={logoDark}
                    alt="HyperSrot logo"
                    className="w-[109px] h-[26px] md:w-[148px] md:h-[35px]"
                />
                <p className="text-secondary text-right text-nowrap text-xs md:text-sm">
                    Date: {date}
                </p>
            </div>
            <div className="flex justify-between items-start">
                <div>
                    <P className="text-xs text-secondary text-wrap w-[63%] sm:text-sm">
                        Hypersrot Technologies, Office No 6, 3rd Floor , Tulsi
                        Green Balewadi High Street Road, Pune, Maharashtra,
                        411045
                    </P>
                </div>
                <div className="flex flex-col justify-end text-right">
                    <P className="text-xs  sm:text-sm">To,</P>
                    <div className="flex flex-col justify-end ">
                        <P className="text-secondary text-xs   sm:text-sm">
                            {propertyName}
                        </P>
                        <P className="text-secondary text-xs  sm:text-sm">
                            {street}
                        </P>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Heading;
