import { Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import ModalAlt from "../../../components/modal/ModalAlt";
import { ERROR_MSG, queryData } from "../../../constants/common";
import { RESET_LOADER, SET_LOADER } from "../../../constants/contexConstant";
import { ENQUIRY_CONSUMER_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import request from "../../../services/request";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import FormInputBottomLine from "../../../shared/ui/Form/FormInputBottomLine";
import RadioInput from "../../../shared/ui/Form/RadioInput";
import { enquiryValidator } from "../../../shared/ui/Form/schema";

const EnquiryPopUp = ({
    isOpen,
    onClose,

    disabled = true,
}) => {
    const { dispatch } = useContextState();
    const [queryType, setQueryType] = useState(queryData[0]);
    const handleSubmit = async (values, actions) => {
        try {
            let body = {
                consumerType: queryType
                    .toUpperCase()
                    .trim()
                    .replaceAll(" ", "_"),
                name: values.name,
                mobile: values.mobile,
                email: values.email,
                averageMonthlyBill: values.averageMonthlyBill,
                locationPincode: values.locationPincode,
            };
            dispatch({ type: SET_LOADER });
            let res = await request.post({
                endpoint: ENQUIRY_CONSUMER_ENDPOINT,
                body,
            });
            dispatch({ type: RESET_LOADER });
            actions.resetForm();
            toast.success(res?.data?.message || "Lead added successfully");
            setQueryType(queryData[0]);
            onClose();
        } catch (error) {
            dispatch({ type: RESET_LOADER });
            toast.error(typeof error === "string" ? error : ERROR_MSG);
            onClose();
        }
    };
    return (
        <ModalAlt
            isOpen={isOpen}
            backgroundColor="white"
            onClose={onClose}
            innerTimes={false}
            topPadding={"30px"}
            xsp="0px"
            xxsp="0px"
        >
            <Formik
                onSubmit={handleSubmit}
                initialValues={{
                    name: "",
                    mobile: "",
                    email: "",
                    averageMonthlyBill: "",
                    locationPincode: "",
                }}
                validationSchema={enquiryValidator}
            >
                {({ isSubmitting, isValid }) => (
                    <Form className=" rounded-r-2 border-y-blue-500 flex-1 px-3 py-4 lg:px-4 lg:py-2">
                        <P className="mb-1.2">I'm looking for</P>
                        <div className="grid  gap-2 lg:gap-1 xl:gap-0.1 grid-cols-2  lg:grid-cols-2 xl:grid-cols-none xl:flex justify-between   2xl:grid-cols-4">
                            {queryData.map((item, i) => (
                                <RadioInput
                                    defaultChecked={item === queryData[0]}
                                    label={item}
                                    id={`${i}_year_term`}
                                    name="query"
                                    color="primary"
                                    activeColor="black800"
                                    value={item}
                                    onChange={(e) => {
                                        setQueryType(e.target.value);
                                    }}
                                />
                            ))}
                        </div>
                        <div className="grid gird-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-2 gap-y-4">
                            <FormInputBottomLine
                                name="name"
                                placeholder="Name*"
                            />
                            <FormInputBottomLine
                                name="mobile"
                                placeholder="Mobile Number*"
                            />
                            <FormInputBottomLine
                                name="email"
                                placeholder="Email*"
                            />

                            <FormInputBottomLine
                                name="locationPincode"
                                placeholder="Location Pin Code*"
                            />
                            <FormInputBottomLine
                                name="averageMonthlyBill"
                                placeholder="Avg Monthly Electricity Bill*"
                            />
                            <div className="flex flex-col justify-end ">
                                <Button
                                    disabled={isSubmitting || !isValid}
                                    isLoading={isSubmitting}
                                    type="submit"
                                    className="w-full py-1 "
                                >
                                    Show Your Interest
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalAlt>
    );
};

export default EnquiryPopUp;
