import React, { forwardRef, useEffect, useState } from "react";
import BorderedContainer from "../../app/proposal/proposalDetails/BorderedContainer";
import DetailsTable from "../../app/proposal/proposalDetails/DetailsTable";
import Features from "../../app/proposal/proposalDetails/Features";
import Heading from "../../app/proposal/proposalDetails/Heading";
import TermsAndConditions from "../../app/proposal/proposalDetails/TermsAndConditions";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import TableComponent from "../../shared/ui/Table";
import {
    IconDuration,
    IconInsurance,
    IconInvestment,
    IconMaintenance,
    IconSubsidy,
    IconTransfer,
} from "../js/icons";

let getOfferingFeatures = (availSubsidy, plantDuration) => {
    return [
        {
            icon: IconInvestment,
            title: "Upfront Investment",
            value: "ZERO",
        },
        {
            icon: IconSubsidy,
            title: "Avail Subsidy",
            value: availSubsidy,
        },
        {
            icon: IconTransfer,
            title: "Plan Duration",
            value: plantDuration,
        },
        {
            icon: IconDuration,
            title: "Ownership Transfer",
            value: `After ${plantDuration} Years`,
        },
        {
            icon: IconMaintenance,
            title: "Maintenance",
            value: "FREE",
        },
        {
            icon: IconInsurance,
            title: "Insurance",
            value: "FREE",
        },
    ];
};
const columns = ["Description", "Plan Details"];

let getLeasePlantDetails = (data) => {
    return [
        {
            Description: "Fixed Monthly Rental",
            "Plan Details": (
                <>
                    <span className="line-through text-gray-400 mr-2">
                        {data?.averageMonthlyBill}
                    </span>
                    <span className="font-bold text-gray-900">
                        {data?.fixedMonthlyRental}
                    </span>
                </>
            ),
        },
        { Description: "Bank Guarantee", "Plan Details": data?.bankGuarantee },
        {
            Description: "Savings on Existing Bills",
            "Plan Details": `${data?.savingsOnExistingBill}%`,
        },
        {
            Description: "Government Subsidy",
            "Plan Details": data?.governmentSubsidy,
        },
        {
            Description: "Effective Savings Per Month",
            "Plan Details": data?.effectiveSavingsPerMonth,
        },
    ];
};

let buildBillingSummary = (
    averageMonthlyBill,
    unitConsumption,
    solarPlantRequired
) => {
    return [
        {
            title: "Average monthly Bill",
            value: `${averageMonthlyBill}`,
            unit: "",
        },
        {
            title: "Unit Consumption",
            value: `${unitConsumption}`,
            unit: "kWh",
        },
        {
            title: "Solar Plant Required",
            value: solarPlantRequired,
            unit: "KWp",
        },
    ];
};

let buildSavingsAtGlanceTableData = (data) => {
    return [
        {
            title: "Monthly Saving",
            value: data?.monthlySavings,
            unit: "",
        },
        {
            title: "Effective Savings Percentage",
            value: data?.effectiveSavingsPerMonth,
            unit: "",
        },
        {
            title: "Saving in 5 Years",
            value: `${data?.savingsWithPlan} + ${data?.savingsWithGovernmentSubsidy}`,
            unit: "",
        },
        {
            title: "Lifetime Saving*",
            value: data?.lifeTimeSavings,
            unit: "",
        },
    ];
};
const SolarLeaseTemplate = forwardRef(({ data }, ref) => {
    const [lease, setLease] = useState(data);
    useEffect(() => {
        setLease(data);
    }, [data]);
    return (
        <div ref={ref} className="flex flex-col gap-3.2">
            <Heading
                date={lease?.date}
                propertyName={lease?.propertyName}
                street={lease?.fullAddress || lease?.streetAddress}
            />
            <H4 className="text-center !text-base font-semibold w-full">
                Proposal
            </H4>
            <div className="bg-white  gap-3.2 p-2 border-2 border-gray-220 rounded-xl border-dashed">
                <p className="text-xs  text-primary md:text-base lg:text-lg mb-2 font-medium">
                    Existing Bill Summary
                </p>
                <div className={`flex justify-between items-center gap-1`}>
                    {buildBillingSummary(
                        lease?.averageMonthlyBill,
                        lease?.unitConsumption,
                        lease?.solarPlantRequired
                    ).map((card, index) => (
                        <div
                            key={index}
                            className={`border flex-col gap-2 rounded-lg bg-gray-170 py-1 px-2 min-w-[29%]`}
                        >
                            <p className=" text-xs md:text-sm text-blue text-nowrap">
                                {card.title}
                            </p>
                            <P className="font-semibold text-nowrap">
                                {card.value}
                                <span className=" text-sm xl:text-base font-normal text-primary">
                                    {card.unit}
                                </span>
                            </P>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex  gap-1 items-stretch">
                <DetailsTable heading="Solar Lease Plan Details">
                    <TableComponent
                        columns={columns}
                        data={getLeasePlantDetails(lease)}
                        headerColor="bg-gray-170 rounded-xl"
                        oddRowColor=""
                        additionalRowClassName="bg-white"
                        borderStyle="border border-gray-200 w-[50%]"
                        fontSize="text-xs"
                        rowStyles={{
                            boxShadow:
                                "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                        }}
                    />
                </DetailsTable>
                <Features
                    details={getOfferingFeatures(
                        lease?.availSubsidy,
                        lease?.plantDuration
                    )}
                />
            </div>

            <div className="bg-white   gap-3.2 p-2 border-2 border-gray-220 rounded-xl border-dashed mt-[5rem]">
                <p className="  text-primary md:text-base lg:text-lg mb-2 font-medium">
                    Savings at Glance
                </p>
                <div className={`flex justify-between items-center gap-1`}>
                    {buildSavingsAtGlanceTableData(lease).map((card, index) => (
                        <div
                            key={index}
                            className={`border flex-col gap-1 rounded-lg bg-gray-170 py-1 pl-0.5 pr-0.5 min-w-[22%]`}
                        >
                            <p className=" text-xs md:text-sm text-blue text-nowrap">
                                {card.title}
                            </p>
                            <p className="font-semibold text-sm text-nowrap">
                                {card.value}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <BorderedContainer>
                {lease?.onboardingSteps && (
                    <TermsAndConditions
                        heading="Onboarding Steps"
                        terms={lease?.onboardingSteps}
                    />
                )}
            </BorderedContainer>
            <BorderedContainer>
                {lease?.termsAndConditions && (
                    <TermsAndConditions
                        heading="Terms & Conditions"
                        terms={lease?.termsAndConditions}
                    />
                )}
            </BorderedContainer>
        </div>
    );
});

export default SolarLeaseTemplate;
