import React, { useCallback } from "react";
import HashLoader from "react-spinners/HashLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RESET_ERROR } from "../../constants/contexConstant";
import { useContextState } from "../../context/ContextProvider";
import P from "../../shared/typography/P";
import ErrorDialog from "../error/ErrorDialog";
import Overlay from "../overlay/Overlay";

const RequestHandler = () => {
    const { state, dispatch } = useContextState();
    const resetErrorHandler = useCallback(() => {
        dispatch({ type: RESET_ERROR });
    }, [dispatch]);

    return (
        <>
            {state.error ? (
                <ErrorDialog error={state.error} onClose={resetErrorHandler} />
            ) : null}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={true}
                draggable={false}
                pauseOnHover
                style={{ top: "80px" }}
            />
            {state.loading ? (
                <Overlay>
                    {/* <Image src={srotLoader} width="120px" height="120px" /> */}
                    {/* <LoadingSpinner /> */}
                    <span className="text-blue">
                        <HashLoader
                            color="#4B6BFB"
                            loading={true}
                            size={90}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </span>

                    <P className="pt-1 text-white font-urbanist">LOADING...</P>
                </Overlay>
            ) : null}
        </>
    );
};

export default RequestHandler;
