import React, { useState } from "react";
import { IoTrash } from "react-icons/io5";
import ModalAlt from "../../components/modal/ModalAlt";
import { P } from "../../components/styles/Typography.styles";
import cn from "../../lib/cn";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";

const handleAdditionalInputError = (inputs) => {
    let allErrors = {};
    inputs.forEach((el, i) => {
        let elementErrors = {};
        if (el.itemDescription === "") {
            elementErrors.itemDescription = "This field is required!";
        }
        if (el.itemPrice === "") {
            elementErrors.itemPrice = "This field is required!";
        } else if (isNaN(el.itemPrice)) {
            elementErrors.itemPrice = "Must be numeric values";
        }

        if (elementErrors?.itemDescription || elementErrors?.itemPrice) {
            allErrors[i] = elementErrors;
        }
    });
    return allErrors;
};
const AdditionalInput = ({
    isOpen,
    onClose,
    inputs,
    setInputs,
    onQuitModal,
    isAllDeletable = false,
}) => {
    const [inputErrors, setInputErrors] = useState({});
    const handleAddMoreInputs = () => {
        let allErrors = handleAdditionalInputError(inputs);
        setInputErrors(allErrors);

        if (Object.keys(allErrors).length === 0) {
            setInputs([
                ...inputs,
                { itemDescription: "", itemPrice: "", itemGst: "" },
            ]);
        }
    };

    const handleChange = (event, index) => {
        let { name, value } = event.target;
        let onChangeValue = inputs.map((item, idx) =>
            idx === index ? { ...item, [name]: value } : item
        );
        setInputs(onChangeValue);

        if (inputErrors[index] && value !== "") {
            let newErrors = inputErrors;
            newErrors[index][name] = "";
            setInputErrors(newErrors);
        }
    };

    const handleDeleteInput = (index) => {
        let newErrors = inputErrors;
        delete newErrors[index];
        setInputErrors(newErrors);
        setInputs(inputs.filter((_, i) => i !== index));
    };

    const handleSubmitAdditionalInput = () => {
        let allErrors = handleAdditionalInputError(inputs);
        setInputErrors(allErrors);

        if (Object.keys(allErrors).length === 0) {
            onClose(false);
        }
    };
    const handleClose = () => {
        onClose(false);
        setInputErrors({});
        onQuitModal();
    };
    let inputLength = inputs?.length === 0;
    return (
        <>
            <ModalAlt innerTimes={false} onClose={handleClose} isOpen={isOpen}>
                <div className="w-full">
                    <div className="flex justify-between gap-2">
                        <div className="w-1/2">
                            <P>Description</P>
                        </div>
                        <div className="w-1/2">
                            <P>Value</P>
                        </div>
                    </div>
                    {inputLength && (
                        <div className="flex justify-end">
                            <button
                                onClick={handleAddMoreInputs}
                                className=" mr-3.2 md:mr-3.6 bg-transparent inline-block items-start underline text-blue-100 text-left text-base"
                            >
                                Add row
                            </button>
                        </div>
                    )}
                    {inputs?.map((inputItem, index) => (
                        <div key={index} className="mb-1">
                            <div className="flex items-center justify-between gap-1">
                                <div className="flex-1 flex flex-wrap md:flex-nowrap md:gap-2">
                                    <div className="w-full md:w-1/2">
                                        <TextField
                                            onChange={(e) =>
                                                handleChange(e, index)
                                            }
                                            name="itemDescription"
                                            value={inputItem.itemDescription}
                                            placeholder="Description"
                                            errorMsg={
                                                inputErrors[index]
                                                    ?.itemDescription
                                            }
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2">
                                        <TextField
                                            onChange={(e) =>
                                                handleChange(e, index)
                                            }
                                            name="itemPrice"
                                            placeholder="Value"
                                            value={inputItem.itemPrice}
                                            errorMsg={
                                                inputErrors[index]?.itemPrice
                                            }
                                        />
                                    </div>
                                </div>

                                <div className={cn("w-[24px] ")}>
                                    {isAllDeletable ? (
                                        <button
                                            className={
                                                "text-xxl hover:text-red"
                                            }
                                            onClick={() =>
                                                handleDeleteInput(index)
                                            }
                                        >
                                            <IoTrash />
                                        </button>
                                    ) : index !== 0 ? (
                                        <button
                                            className={
                                                "text-xxl hover:text-red"
                                            }
                                            onClick={() =>
                                                handleDeleteInput(index)
                                            }
                                        >
                                            <IoTrash />
                                        </button>
                                    ) : null}
                                </div>
                            </div>

                            <div className="flex flex-wrap items-center justify-between gap-1 mt-1">
                                <div className="flex items-center">
                                    <label htmlFor="">
                                        <P className="md:text-base xl:text-sm 2xl:text-sm">
                                            Gst Percentage
                                        </P>
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => handleChange(e, index)}
                                        name="itemGst"
                                        placeholder="Value"
                                        value={`${inputItem.itemGst}`}
                                        className="border-b border-blue-200 pl-1 p text-base w-[90px]  focus:outline-none focus:border-blue-100"
                                    />
                                </div>

                                {index === inputs.length - 1 && (
                                    <button
                                        onClick={handleAddMoreInputs}
                                        className=" mr-3.2 md:mr-3.6 bg-transparent inline-block items-start underline text-blue-100 text-left text-base"
                                    >
                                        Add row
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-center mt-2 ">
                        <Button
                            onClick={handleSubmitAdditionalInput}
                            className="w-full sm:w-3/6"
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </ModalAlt>
        </>
    );
};

export default AdditionalInput;
