import React, { useEffect, useState } from "react";
import { IoTrash } from "react-icons/io5";
import { phoneRegEx } from "../../constants/regularExpression";
import cn from "../../lib/cn";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";

const handleMemberInputError = (inputs, admins) => {
    let allErrors = {};
    const phoneSet = new Set(); // To track unique phone numbers

    inputs.forEach((el, i) => {
        let elementErrors = {};

        if (el.adminName === "") {
            elementErrors.adminName = "This field is required!";
        }

        if (el.adminPhone === "") {
            elementErrors.adminPhone = "This field is required!";
        } else if (isNaN(el.adminPhone)) {
            elementErrors.adminPhone = "Must be numeric values";
        } else if (!phoneRegEx.test(el.adminPhone)) {
            elementErrors.adminPhone = "Phone number is invalid";
        } else if (phoneSet.has(el.adminPhone)) {
            elementErrors.adminPhone = "This phone number is already used.";
        } else {
            phoneSet.add(el.adminPhone);
        }

        const isPhoneInAdmins = admins?.some(
            (admin) => admin.phone.slice(2) === el.adminPhone
        );

        if (isPhoneInAdmins) {
            elementErrors.adminPhone = "This phone number is already used.";
        }

        if (elementErrors?.adminName || elementErrors?.adminPhone) {
            allErrors[i] = elementErrors;
        }
    });

    return allErrors;
};
const MemberInput = ({
    inputs,
    setInputs,
    handleInvite,
    handleQuit,
    isAllDeletable = false,
    admins,
}) => {
    useEffect(() => {}, [inputs]);
    const [inputErrors, setInputErrors] = useState({});
    const handleAddMoreInputs = () => {
        let allErrors = handleMemberInputError(inputs);
        setInputErrors(allErrors);

        if (Object.keys(allErrors).length === 0) {
            setInputs([
                ...inputs,
                { adminName: "", adminPhone: "" /*adminRole: "" */ },
            ]);
        }
    };

    const handleChange = (event, index) => {
        let { name, value } = event.target;
        let onChangeValue = inputs.map((item, idx) =>
            idx === index ? { ...item, [name]: value } : item
        );
        if (name === "adminPhone") {
            onChangeValue = inputs.map((item, idx) =>
                idx === index ? { ...item, [name]: value } : item
            );
        }

        setInputs(onChangeValue);

        if (inputErrors[index] && value !== "") {
            let newErrors = inputErrors;
            newErrors[index][name] = "";
            setInputErrors(newErrors);
        }
    };
    const handleSelectInput = (value, index) => {
        let onChangeValue = inputs.map((item, idx) =>
            idx === index ? { ...item, ["adminRole"]: value } : item
        );
        setInputs(onChangeValue);

        if (inputErrors[index] && value !== "") {
            let newErrors = inputErrors;
            newErrors[index]["role"] = "";
            setInputErrors(newErrors);
        }
    };

    const handleDeleteInput = (index) => {
        let newErrors = inputErrors;
        delete newErrors[index];
        setInputErrors(newErrors);
        setInputs(inputs.filter((_, i) => i !== index));
    };

    const handleSubmitMemberInput = () => {
        let allErrors = handleMemberInputError(inputs, admins);
        setInputErrors(allErrors);

        if (Object.keys(allErrors).length === 0) {
            handleInvite();
            handleQuit();
        }
    };
    useEffect(() => {
        setInputErrors({});
    }, []);
    let inputLength = inputs?.length === 0;
    return (
        <>
            <div className="w-full">
                {inputLength && (
                    <div className="flex justify-end">
                        <button
                            onClick={handleAddMoreInputs}
                            className=" mr-3.2 md:mr-3.6 bg-transparent inline-block items-start underline text-blue-100 text-left text-base"
                        >
                            Add row
                        </button>
                    </div>
                )}
                {inputs?.map((inputItem, index) => (
                    <div key={index} className="mb-1">
                        <div className="flex items-center justify-between gap-1">
                            <div className="flex-1 flex flex-wrap items-center gap-1 md:flex-nowrap md:gap-2">
                                <div className="w-full md:w-1/2">
                                    <TextField
                                        onChange={(e) => handleChange(e, index)}
                                        name="adminName"
                                        value={inputItem.adminName}
                                        placeholder="Admin Name"
                                        errorMsg={inputErrors[index]?.adminName}
                                    />
                                </div>
                                <div className="w-full md:w-1/2">
                                    <TextField
                                        onChange={(e) => handleChange(e, index)}
                                        name="adminPhone"
                                        placeholder="Admin Phone Number"
                                        value={inputItem.adminPhone}
                                        errorMsg={
                                            inputErrors[index]?.adminPhone
                                        }
                                    />
                                </div>
                                {/*  <div className="w-full md:w-1/3 ">
                                    <Dropdown
                                        selected={inputItem.adminRole}
                                        onSelect={(value) =>
                                            handleSelectInput(value, index)
                                        }
                                        label={""}
                                        placeholder="Role"
                                        inputClass="lg:py-[1.3rem]"
                                        className={"flex-1 "}
                                        data={["Admin", "Member"]}
                                        icon={<BsArrowDown />}
                                    />
                                </div>*/}
                            </div>

                            <div className={cn("w-[24px] ")}>
                                {isAllDeletable ? (
                                    <button
                                        className={"text-xxl hover:text-red"}
                                        onClick={() => handleDeleteInput(index)}
                                    >
                                        <IoTrash />
                                    </button>
                                ) : index !== 0 ? (
                                    <button
                                        className={"text-xxl hover:text-red"}
                                        onClick={() => handleDeleteInput(index)}
                                    >
                                        <IoTrash />
                                    </button>
                                ) : null}
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center justify-end gap-1 mt-1">
                            {index === inputs.length - 1 && (
                                <button
                                    onClick={handleAddMoreInputs}
                                    className=" mr-3.2 md:mr-3.6 bg-transparent inline-block items-start underline text-blue-100 text-left text-base"
                                >
                                    Add row
                                </button>
                            )}
                        </div>
                    </div>
                ))}
                <div className="flex justify-center mt-2 ">
                    <Button
                        onClick={handleSubmitMemberInput}
                        className="w-full sm:w-3/6"
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    );
};

export default MemberInput;
