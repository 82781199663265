import React from "react";
import { aboutUsBg } from "../../resources/js/images";
import Hero from "../home/Hero";
import HeroDetails from "./HeroDetails";
import PropertyContent from "./PropertyContent";

const PropertyDetails = () => {
    return (
        <div className="mb">
            <Hero
                containerClass={
                    "mb-6.4 sm:mb-[12rem] lg:mb-10.4 xl:mb-6.2  2xl:mb-6.4"
                }
                wrapperClass="h-[600px] sm:h-[300px] xl:h-[300px]"
                align={"items-end px-2 relative bottom-0"}
                content={<HeroDetails />}
                url={aboutUsBg}
            />
            <PropertyContent />
        </div>
    );
};

export default PropertyDetails;
