import { Autocomplete } from "@react-google-maps/api";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePickerBox from "react-date-picker";
import { fromLatLng } from "react-geocode";
import { RxCalendar } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import ModalAlt from "../../components/modal/ModalAlt";
import { ERROR_MSG } from "../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../constants/contexConstant";
import {
    emailRegEx,
    phoneRegEx,
    pinRegEx,
} from "../../constants/regularExpression";
import { NEW_ENQUIRY_ENDPOINT } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import {
    getCityAddressPinCode,
    getLatLongGeocoder,
} from "../../helpers/helpers";
import cn from "../../lib/cn";
import { check } from "../../resources/js/images";
import request from "../../services/request";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";
import ReusableMap from "../../shared/ui/Map";
import { DatePickerWrapper } from "../onboarding/heroDetails";
import Stepper from "../onboarding/Stepper";
const center = {
    lat: 18.5204303,
    lng: 73.8567437,
};
const AdminEnquiry = ({ isOpen, onClose }) => {
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContextState();
    const [currentStep, setCurrentStep] = useState(1);
    const [errorMessage, setErrorMsg] = useState("");

    const [markerPointA, setMarkerPointA] = useState(center);
    const siteRef = useRef(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        electricalBill: "",
        date: "",
        address: "",
    });
    const [manualAddress, setManualAddress] = useState({
        address: "",
        city: "",
        state: "",
        pincode: "",
    });

    const [useManualAddress, setUseManualAddress] = useState(true);
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };
    const handleDate = (value) => {
        setFormData({ ...formData, date: value });
        setErrors({ ...errors, date: "" });
    };
    const handleManualAddressChange = (e) => {
        setManualAddress({ ...manualAddress, [e.target.name]: e.target.value });
    };
    const [autoFillAddress, setAutoFillAddress] = useState(
        "Pune, Maharashtra, India"
    );
    useEffect(() => {
        setCurrentStep(1);
        setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            electricalBill: "",
            date: "",
            address: "",
        });
        setManualAddress({
            address: "",
            city: "",
            state: "",
            pincode: "",
        });
    }, [isOpen]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate(
            formData,
            autoFillAddress,
            useManualAddress ? manualAddress : null
        );
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            if (currentStep === 1) {
                setCurrentStep(2);
            } else if (currentStep === 2) {
                handleCompleteEnquiry();
            }
        }
    };
    const validate = (formData, autoFillAddress, customAddress) => {
        const newErrors = {};
        if (currentStep === 1) {
            if (!formData.name.trim()) {
                newErrors.name = "Name is required";
            }
            if (!formData.email) {
                newErrors.email = "Email is Required";
            } else if (
                formData.email &&
                !emailRegEx.test(formData.email.trim())
            ) {
                newErrors.email = "Email is invalid";
            }
            if (!formData.phoneNumber.trim()) {
                newErrors.phoneNumber = "Phone number is required";
            } else if (
                formData.phoneNumber &&
                !phoneRegEx.test(formData.phoneNumber)
            ) {
                newErrors.phoneNumber = "Phone number is invalid";
            }
            if (!formData.electricalBill.trim()) {
                newErrors.electricalBill = "Electricity Bill is required";
            } else if (
                formData.electricalBill &&
                isNaN(formData.electricalBill)
            ) {
                newErrors.electricalBill = "Bill must be valid number";
            }
        }
        if (currentStep > 1) {
            if (!autoFillAddress) {
                newErrors.address = "Address is required";
            }
            if (!formData?.date) {
                newErrors.date = "Date is required";
            } else if (moment(formData.date).isValid() === false) {
                newErrors.date = "Date is invalid!";
            } else {
                const currentMoment = moment().startOf("day").unix();
                const maxTimeEndDays = moment()
                    .add(13, "days")
                    .endOf("day")
                    .unix();
                const userMoment = moment(formData.date).startOf("days").unix();
                if (currentMoment > userMoment) {
                    newErrors.date = "Past date is invalid!";
                } else if (maxTimeEndDays < userMoment) {
                    newErrors.date = "Date can't be more than 2 weeks!";
                }
            }
            if (customAddress) {
                if (!customAddress.address) {
                    newErrors.address = "Address is required";
                }
                if (!customAddress.city) {
                    newErrors.city = "City is required";
                }
                if (!customAddress.state) {
                    newErrors.state = "State is required";
                }
                if (!customAddress.pincode) {
                    newErrors.pincode = "Pin code is required";
                } else if (
                    customAddress.pincode &&
                    !pinRegEx.test(customAddress.pincode)
                ) {
                    newErrors.pincode = "Invalid pin code";
                }
            }
        }
        return newErrors;
    };
    const handleCompleteEnquiry = async () => {
        try {
            let optionalResult = {};
            if (!manualAddress) {
                if (
                    !autoFillAddress?.pinCode ||
                    !autoFillAddress?.city ||
                    !autoFillAddress?.state
                ) {
                    let res = await fromLatLng(
                        autoFillAddress?.lat,
                        autoFillAddress?.lng
                    );

                    optionalResult = {
                        ...getCityAddressPinCode(
                            res.results[0].address_components
                        ),
                    };
                }
            }

            let reqData = {
                date: `${moment(formData.date).unix()}`,
                bill: formData.electricalBill,
                phone: `91${formData.phoneNumber}`,
                name: formData.name,
                email: formData.email,
            };

            if (useManualAddress) {
                const latLongGeoCoder = await getLatLongGeocoder(
                    `${manualAddress.address}, ${manualAddress.city}, ${manualAddress.state}, ${manualAddress.pincode}`
                );
                reqData.city = manualAddress.city;
                reqData.state = manualAddress.state;
                reqData.pinCode = manualAddress.pincode;
                reqData.address = manualAddress.address;
                reqData.latitude = latLongGeoCoder.lat;
                reqData.longitude = latLongGeoCoder.lng;
            } else {
                reqData.city = autoFillAddress?.city || optionalResult?.city;
                reqData.state = autoFillAddress?.state || optionalResult?.state;
                reqData.pinCode =
                    autoFillAddress?.pinCode || optionalResult?.pinCode;
                reqData.latitude = autoFillAddress?.lat;
                reqData.longitude = autoFillAddress?.lng;
                reqData.address = siteRef.current.value;
            }

            setLoading(true);
            dispatch({ type: SET_LOADER });
            await request.authPost({
                endpoint: NEW_ENQUIRY_ENDPOINT,
                body: reqData,
            });
            dispatch({ type: RESET_LOADER });
            setLoading(false);
            setCurrentStep(3);
        } catch (error) {
            dispatch({ type: RESET_LOADER });
            setLoading(false);
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    const handlePlaceChanged = async () => {
        const res = await getLatLongGeocoder(siteRef.current.value);

        setAutoFillAddress(res);
        setMarkerPointA(res);
    };
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 13);

    const renderStepFields = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                            <TextField
                                label={"Full Name"}
                                value={formData?.name}
                                onChange={handleChange}
                                name={"name"}
                                type={"text"}
                                errorMsg={errors?.name}
                                required
                            />
                            <div>
                                <TextField
                                    label={"Email Id"}
                                    value={formData?.email}
                                    onChange={handleChange}
                                    name={"email"}
                                    type={"text"}
                                    errorMsg={errors?.email}
                                    required
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 mt-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6 ">
                            <div>
                                <TextField
                                    label={"Phone Number"}
                                    value={formData?.phoneNumber}
                                    onChange={handleChange}
                                    name={"phoneNumber"}
                                    type={"text"}
                                    errorMsg={errors?.phoneNumber}
                                    required
                                />
                            </div>
                            <div>
                                <TextField
                                    label={"Bill Amount"}
                                    value={formData?.electricalBill}
                                    onChange={handleChange}
                                    name={"electricalBill"}
                                    type={"text"}
                                    errorMsg={errors?.electricalBill}
                                    required
                                />
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="grid grid-cols-1  gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                            <div className="w-full">
                                <div className="w-full flex flex-col">
                                    <label
                                        className="text-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8 "
                                        htmlFor={"date"}
                                    >
                                        Survey Date
                                    </label>

                                    <div
                                        className={` relative px-px py-px  border w-full  rounded-lg border-blue-400 text-btn-s`}
                                    >
                                        <DatePickerWrapper>
                                            <DatePickerBox
                                                onChange={handleDate}
                                                value={formData?.date}
                                                dayPlaceholder="dd"
                                                name="date"
                                                monthPlaceholder="mm"
                                                format="dd/MM/yyyy"
                                                yearPlaceholder="yyyy"
                                                minDate={new Date()}
                                                maxDate={maxDate}
                                                clearIcon={null}
                                                calendarIcon={
                                                    <RxCalendar size={20} />
                                                }
                                                className={`w-full flex border-none rounded-lg text-primary bg-white h-[40px] md:h-[53px]`}
                                                calendarClassName="relative top-1 !border-2 !border-blue-400 bg-white rounded-lg"
                                                wrapperClassName={`bg-white !border-none w-full`}
                                                inputClassName="text-primary border-none w-full"
                                            />
                                        </DatePickerWrapper>
                                    </div>
                                    {errors.date && (
                                        <span className="text-red text-btn-s">
                                            {errors.date}*
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 mt-2 ">
                            {!useManualAddress ? (
                                isLoaded ? (
                                    <>
                                        <div>
                                            <label className="text-secondary font-normal text-sm sm:text-base xl:text-lg  ">
                                                Enter Address{" "}
                                                <span className="text-red">
                                                    *
                                                </span>
                                            </label>
                                            <Autocomplete
                                                onPlaceChanged={
                                                    handlePlaceChanged
                                                }
                                            >
                                                <div
                                                    className={cn(
                                                        "relative px-px py-px border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-btn-s bg-custom-gradient mt-0.8",
                                                        {
                                                            "border-red":
                                                                errors.address,
                                                        }
                                                    )}
                                                >
                                                    <input
                                                        className={`border-none w-full  rounded-lg px-1  py-1  lg:py-1.2
                                                        text-primary font-normal text-sm sm:text-base xl:text-base 2xl:text-xl
                    `}
                                                        placeholder="Enter Address"
                                                        label="Enter Your Address"
                                                        id="siteLocationId"
                                                        shadow={false}
                                                        ref={siteRef}
                                                        isError={errors.address}
                                                    />
                                                </div>
                                            </Autocomplete>
                                        </div>

                                        {errors.address && (
                                            <span className="text-red text-btn-s">
                                                {errors.address}*
                                            </span>
                                        )}
                                    </>
                                ) : (
                                    <P>Loading...</P>
                                )
                            ) : (
                                <div className="grid grid-cols-2 gap-x-4 gap-y-2 mt-2">
                                    <TextField
                                        label="Address"
                                        placeholder="Address"
                                        value={manualAddress.address}
                                        onChange={handleManualAddressChange}
                                        name="address"
                                        errorMsg={errors.address}
                                    />
                                    <TextField
                                        label="City"
                                        placeholder="City"
                                        value={manualAddress.city}
                                        onChange={handleManualAddressChange}
                                        name="city"
                                        errorMsg={errors.city}
                                    />
                                    <TextField
                                        label="State"
                                        placeholder="State"
                                        value={manualAddress.state}
                                        onChange={handleManualAddressChange}
                                        name="state"
                                        errorMsg={errors.state}
                                    />
                                    <TextField
                                        label="Pincode"
                                        placeholder="Pincode"
                                        value={manualAddress.pincode}
                                        onChange={handleManualAddressChange}
                                        name="pincode"
                                        errorMsg={errors.pincode}
                                    />
                                </div>
                            )}
                            <div>
                                <button
                                    onClick={() =>
                                        setUseManualAddress(!useManualAddress)
                                    }
                                    type="button"
                                    className="mt-2 text-blue text-xs md:text-sm underline inline-block"
                                >
                                    {useManualAddress
                                        ? "Select address on Google Map"
                                        : "Unable to find address? Enter manually"}
                                </button>
                            </div>
                        </div>
                        {!useManualAddress && (
                            <div className="grid grid-cols-1 gap-1.6 mt-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                                <ReusableMap
                                    center={markerPointA}
                                    dimensions="w-[100%] h-[200px]"
                                    address={autoFillAddress}
                                    callback={() => setIsLoaded(true)}
                                />
                            </div>
                        )}
                    </>
                );
            case 3:
                return (
                    <div className="flex flex-col justify-center items-center mt-5">
                        <img
                            src={check}
                            className="w-44 h-44"
                            alt="check_icon"
                        />
                        <P className={"text-center w-[70%] my-5"}>
                            Customer enquiry is successfully saved.
                        </P>
                    </div>
                );

            default:
                return null;
        }
    };

    const handleStepNavigation = (step) => {
        const validationErrors = validate(
            formData,
            autoFillAddress,
            useManualAddress ? manualAddress : null
        );
        if (
            step !== 3 &&
            (Object.keys(validationErrors).length === 0 || currentStep === 2)
        ) {
            setCurrentStep(step);
        }
    };
    return (
        <ModalAlt
            isOpen={isOpen}
            backgroundColor="transparent"
            onClose={onClose}
            innerTimes={false}
            topPadding={"5px"}
        >
            <div className="flex justify-center ">
                <div className="w-full bg-white rounded-1.5 shadow-md p-2 md:pb-4.6 md:px-3">
                    <div className="md:mx-2">
                        <Stepper
                            currentStep={currentStep}
                            onClick={handleStepNavigation}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        {renderStepFields()}

                        <div className="mt-2">
                            <Button
                                onClick={(e) => {
                                    currentStep > 2
                                        ? onClose()
                                        : handleSubmit(e);
                                }}
                                type="submit"
                                className="w-full flex justify-center px-1 sm:px-1.8 2xl:px-2 py-0.8 sm:py-1.2 xl:py-1.2 "
                                disabled={loading}
                                isLoading={loading}
                            >
                                {currentStep === 1
                                    ? "Next"
                                    : currentStep === 2
                                    ? "Submit details"
                                    : "Done"}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </ModalAlt>
    );
};

export default AdminEnquiry;
