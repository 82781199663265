import React, { forwardRef, useEffect, useState } from "react";
import BorderedContainer from "../../app/proposal/proposalDetails/BorderedContainer";
import DetailsTable from "../../app/proposal/proposalDetails/DetailsTable";
import Features from "../../app/proposal/proposalDetails/Features";
import Heading from "../../app/proposal/proposalDetails/Heading";
import TermsAndConditions from "../../app/proposal/proposalDetails/TermsAndConditions";
import Acceptance from "../../app/proposal/purchase/Acceptance";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import TableComponent from "../../shared/ui/Table";
import { IconMaintenance, IconSubsidy, IconTransfer } from "../js/icons";

const tableProps = {
    headerColor: "bg-gray-170 rounded-xl",
    oddRowColor: "",
    additionalRowClassName: "bg-white",
    borderStyle: "border border-gray-200",
    fontSize: "text-xs md:text-sm",
};

let getOfferingFeatures = (returnOfInvestment, availSubsidy) => {
    return [
        {
            icon: IconSubsidy,
            title: "Avail Subsidy",
            value: availSubsidy,
        },
        {
            icon: IconTransfer,
            title: "Maintenance",
            value: "Free",
        },

        {
            icon: IconMaintenance,
            title: "Return on Investment",
            value: `${returnOfInvestment} Years`,
        },
    ];
};

const columns = ["Sr. No.", "Equipment", "Specification / Make"];

let getSolarPurchaseTableData = (data) => {
    return [
        {
            Description: `Solar Plant Size: ${data?.solarPlantRequired}KWp`,
            "Plan Details": (
                <span className="font-bold">{data?.solarPlantSizeCost}</span>
            ),
        },
        { Description: `GST @ 13.8%`, "Plan Details": data?.gst },
        { Description: "Total Amount", "Plan Details": data?.totalAmount },
        {
            Description: "Government Subsidy",
            "Plan Details": data?.governmentSubsidy,
        },
        {
            Description: "Return on Investments",
            "Plan Details": `${data?.returnOfInvestment} Years`,
        },
    ];
};

const purchaseColumns = ["Description", "Plan Details"];

let getMaterialSpecification = (data) => {
    return [
        {
            "Sr. No.": "1",
            Equipment: "Solar PV Modules",
            "Specification / Make": data?.solarPvModules,
        },
        {
            "Sr. No.": "2",
            Equipment: "Module Mounting Structures",
            "Specification / Make": data?.moduleMountingStructures,
        },
        {
            "Sr. No.": "3",
            Equipment: "Inverter",
            "Specification / Make": data?.inverter,
        },

        {
            "Sr. No.": "4",
            Equipment: "String Monitoring Boxes",
            "Specification / Make": data?.stringMonitoringBoxes,
        },
        {
            "Sr. No.": "5",
            Equipment: "DC Power Cables",
            "Specification / Make": data?.dcPowerCables,
        },
        {
            "Sr. No.": "6",
            Equipment: "AC Power Cables",
            "Specification / Make": data?.acPowerCables,
        },
        {
            "Sr. No.": "7",
            Equipment: "Earthing Electrode",
            "Specification / Make": data?.earthingElectrode,
        },
        {
            "Sr. No.": "8",
            Equipment: "Lighting Arrestor",
            "Specification / Make": data?.lightingArrestor,
        },
        {
            "Sr. No.": "9",
            Equipment: "Connector",
            "Specification / Make": data?.connector,
        },
        {
            "Sr. No.": "10",
            Equipment: "NET METER",
            "Specification / Make": data?.netMeter,
        },
    ];
};

let warrantyColumns = ["Type", "Coverage", "Duration"];

let getWarrantyData = (data) => {
    return [
        {
            Type: "Warranty",
            Coverage: "PV Modules",
            Duration: data?.pvtModulesWarranty,
        },
        {
            Type: "Warranty",
            Coverage: "Module Power out-put",
            Duration: data?.powerOutputWarranty,
        },
        {
            Type: "Warranty",
            Coverage: "Grid Connect String Inverter",
            Duration: data?.gridConnectWarranty,
        },
        {
            Type: "Warranty",
            Coverage: "Rest of the systems",
            Duration: data?.restSystemWarranty,
        },
    ];
};

let buildBillingSummary = (
    averageMonthlyBill,
    unitConsumption,
    solarPlantRequired
) => {
    return [
        {
            title: "Average monthly Bill",
            value: `${averageMonthlyBill}`,
            unit: "",
        },
        {
            title: "Unit Consumption",
            value: `${unitConsumption}`,
            unit: "kWh",
        },
        {
            title: "Solar Plant Required",
            value: solarPlantRequired,
            unit: "KWp",
        },
    ];
};
const SolarPurchaseTemplate = forwardRef(({ data }, ref) => {
    const [purchase, setPurchase] = useState(data);
    useEffect(() => {
        setPurchase(data);
    }, [data]);
    return (
        <div ref={ref} className="flex flex-col gap-3.2">
            <Heading
                date={purchase?.date}
                propertyName={purchase?.propertyName}
                street={purchase?.fullAddress || purchase?.streetAddress}
            />
            <H4 className="text-center !text-base font-semibold w-full">
                Proposal
            </H4>
            <div className="bg-white  gap-3.2 p-2 border-2 border-gray-220 rounded-xl border-dashed">
                <p className="text-xs  text-primary md:text-base lg:text-lg mb-2 font-medium">
                    Existing Bill Summary
                </p>
                <div className={`flex justify-between items-center gap-1`}>
                    {buildBillingSummary(
                        purchase?.averageMonthlyBill,
                        purchase?.unitConsumption,
                        purchase?.solarPlantRequired
                    ).map((card, index) => (
                        <div
                            key={index}
                            className={`border flex-col gap-2 rounded-lg bg-gray-170 py-1 px-2 min-w-[29%]`}
                        >
                            <p className=" text-xs md:text-sm text-blue text-nowrap">
                                {card.title}
                            </p>
                            <P className="font-semibold text-nowrap">
                                {card.value}
                                <span className=" text-sm xl:text-base font-normal text-primary">
                                    {card.unit}
                                </span>
                            </P>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex  gap-2 items-stretch">
                <DetailsTable heading="Solar Purchase Plan Details">
                    <TableComponent
                        columns={purchaseColumns}
                        data={getSolarPurchaseTableData(purchase)}
                        {...tableProps}
                        rowStyles={{
                            boxShadow:
                                "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                        }}
                    />
                </DetailsTable>

                <Features
                    details={getOfferingFeatures(
                        purchase?.returnOfInvestment,
                        purchase?.availSubsidy
                    )}
                    paymentData={{
                        terms: purchase?.paymentTerms,
                        heading: "Payment Terms",
                    }}
                />
            </div>

            <BorderedContainer className={"mt-[3rem]"}>
                {purchase?.termsAndConditions && (
                    <TermsAndConditions
                        heading="Terms & Conditions"
                        terms={purchase?.termsAndConditions}
                    />
                )}
            </BorderedContainer>

            <DetailsTable
                wrapperClass="xl:w-full "
                heading="Material Specification"
            >
                <TableComponent
                    columns={columns}
                    data={getMaterialSpecification(purchase)}
                    {...tableProps}
                    rowStyles={{
                        boxShadow: "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                    }}
                />
            </DetailsTable>

            <DetailsTable
                heading="Warranties and Guarantees"
                wrapperClass="xl:w-full"
            >
                <TableComponent
                    columns={warrantyColumns}
                    data={getWarrantyData(purchase)}
                    {...tableProps}
                    rowStyles={{
                        boxShadow: "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                    }}
                />
            </DetailsTable>
            <div className="mt-[25rem]">
                <Acceptance />
            </div>
        </div>
    );
});

export default SolarPurchaseTemplate;
